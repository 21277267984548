import React from 'react'
import { Link } from 'gatsby';

import s from './arrowLink.module.css'


export default ({ children, to, arrowRight }) => (
  <Link
    to={to}
    className={[s.arrowLink, arrowRight ? s.arrowRight : ''].filter(x => !!x).join(' ')}
  >
    {children}
  </Link>
)
