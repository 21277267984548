import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

import { getPlacementImg } from '../utils/img-helpers';

import Layout from '../components/layout';
import Loading from '../components/loading';
import Button from '../components/button';
import Container from '../components/container';
import ArrowLink from '../components/arrowLink';

import s from './person.module.css'

const netlifyIdentity = require("netlify-identity-widget");

export default ({ data, location} ) => {

  useEffect(() => {
    netlifyIdentity.init({container: "#widgets"});

    const companies = data.allContentfulCompany.edges;
    const currentEmail = (netlifyIdentity.currentUser() && netlifyIdentity.currentUser().email); // || 'h@heiddi.com';
    const currentCompany = companies.find(item => item.node.email === currentEmail);
    SetCompany(currentCompany.node);
    const currentCompanyName = currentCompany && currentCompany.node.name;

    const person = data.contentfulPerson;

    const hasPermission = person.company && person.company.name === currentCompanyName;

    if (hasPermission) {
      SetPerson(person);
      SetLoading(false);
    } else {
      navigate('/404');
    }

  }, [data, netlifyIdentity]);


  const [loading, SetLoading] = useState(true);
  const [person, SetPerson] = useState({});
  const [company, SetCompany] = useState({});

  const [showButton, SetShowButton] = useState(true);
  const [showLoading, SetShowLoading] = useState(false);
  const [showError, SetShowError] = useState(false);
  const [errorMessage, SetErrorMessage] = useState('');;
  const [showMessage, SetShowMessage] = useState(false);

  const onSendPing = (phonenumber, message, from) => {

    SetShowLoading(true);
    SetShowButton(false);

    const smsmessage = message || 'Þín bíður gestur. Skottastu til hans.';
    const smsfrom = from || 'Notify';

    //fetch(`${process.env.SIP_HOST}?user=${process.env.SIP_USER}&pass=${process.env.SIP_TOKEN}&from=Notify&to=7733003&body=Test`)
    fetch(`https://sms.nova.is/v1/?user=S48217&pass=PBIS6Z9uIv6n&from=${smsfrom}&to=${phonenumber}&body=${smsmessage}`)
      .then(
        (result) => {
          //console.log('result', result);
          //SetShowLoading(false);
          //SetShowMessage(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //SetErrorMessage(error.toString());
          //SetShowLoading(false);
          //SetShowError(true);

          //console.log('SetErrorMessage', error);
          //console.log('SetShowError', true);
        }
      );

      SetShowLoading(false);
      SetShowMessage(true);

      setTimeout(() => {
        navigate('/starfsfolk');
      }, 30000);
  }

  const formatTel = (number) => {
    if (number.length < 4) {
      return number;
    }
    return `${number.slice(0, 3)} ${number.slice(3)}`;
  }

  const firstName = person.name && person.name.substr(0, person.name.indexOf(' '));

  return (
    <Layout location={location} noHeader>
      <Helmet title={person.name} />

      {loading ?
      <Loading />
        :
      <Container belowHeader>

        <div className={s.person}>
          <div className={s.personImage}>
            <img src={person.image ? person.image.file.url : getPlacementImg(person.type, 500)} alt={person.name} />
          </div>
          <div className={s.personContent}>
            <div className={s.personName}>
              {person.name}
            </div>
            <div className={s.personJobtitle}>
              {person.jobtitle}
            </div>
            {showButton &&
              <div className={s.personButton}>
                <Button fill onClick={() => onSendPing(person.phonenumber, company.textTheme && company.textTheme.smsText, company.textTheme && company.textTheme.smsFrom)}>Ping!</Button>
              </div>
            }
            {showLoading &&
              <div className={s.personLoading}>
                {company.textTheme && company.textTheme.loadingMessage ?
                  <span>{company.textTheme.loadingMessage}</span>
                  :
                  <span>Læt {firstName} vita að þú ert kominn</span>
                }
              </div>
            }
            {showMessage &&
              <div className={s.personMessage}>
                {company.textTheme && company.textTheme.successMessage ?
                  <span>{company.textTheme.successMessage.replace('{0}', firstName)}</span>
                  :
                  <span>Dokaðu við í augnablik, {firstName} kemur að vörmu spori.</span>
                }
              </div>
            }
            {showError && errorMessage &&
              <div className={s.personErrorMessage}>
                {company.phonenumber ?
                  <span>Því miður tókst ekki að láta {firstName} vita. Vinsamlega hringdu í {formatTel(company.phonenumber)} til að láta vita af þér.</span>
                :
                  <span>Því miður tókst ekki að láta {firstName} vita.</span>
                }
              </div>
            }
          </div>
          <div className={s.backLink}>
            <ArrowLink to="/starfsfolk">
              Starfsfólk
            </ArrowLink>
          </div>
        </div>

      </Container>
      }
    </Layout>
  );

}

export const pageQuery = graphql`
  query PersonBySlug($slug: String!) {

    contentfulPerson(handle: {eq: $slug}) {
      name
      jobtitle
      phonenumber
      company {
        name
      }
      handle
      image {
        file {
          url
        }
      }
    }

    allContentfulCompany {
      edges {
        node {
          email
          name
          phonenumber
          textTheme {
            successMessage
            errorMessage
            smsText
            smsFrom
          }
        }
      }
    }
  }
`
